body {
    background: #fafafa !important;
    overflow-x: hidden !important;
}
.main {
    min-height: 100vh;
    overflow-x: hidden !important;
}
.profile {
    display: flex;
    align-items: cente;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.profile_image img {
    width: 150px;
    height: 150px;
    border: 2px solid #C0007A;
    border-radius: 99999999px;
}
.profile_image {
    margin-right: 100px;
    margin-left: 100px;
}
.username {
    font-size: 22px;
}
.description {
    font-weight: 500;
}

@media (max-width: 600px) {
    .profile_image img {
        width: 100px;
        height: 100px;
    }
    div.posts {
        width: 100% !important;
    }
    .posts img {
        padding: 0;
    }
    .main {
        width: 100% !important;
    }
    .profile_image {
        margin-right: 10px;
        margin-left: 30px;
    }
    .username {
        font-size: 18px;
    }
    .description {
        font-size: 14px;
    }
    .name {
        font-size: 13px !important;
    }
    .main {
        width: 100% !important;
    }
    form.form {
        width: 80% !important;
    }
    .profile {
        margin-left: 0;
        margin-right: 0;
        justify-content: left;
    }
}